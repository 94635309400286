<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('hotel.create hotel')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.area name')}"
                         name="area"
                         :label="$t('common.area name')"
                         rules="required"
                         :options-data="areaList"
                         boundary-class="b-b-0"
                         v-model="entryToSave.area_id"/>
              <AppInput name="hotel_name" :label="$t('hotel.hotel_name')" type="string-length:30" rules="required"
                        v-model="entryToSave.name"/>
              <AppInput name="address" :label="$t('common.address')" type="string" rules="required"
                        v-model="entryToSave.address"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToSave.phone"/>
              <AppInput name="email" :label="$t('common.mail address')" type="email"
                        :rules="V_EMAIL"
                        v-model="entryToSave.email"/>
              <AppInput name="fax" :label="$t('common.fax')" type="number-length:10" rules="fax"
                        v-model="entryToSave.fax"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('hotel.update hotel')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.area name')}"
                         name="area"
                         :label="$t('common.area name')"
                         rules="required"
                         :options-data="areaList"
                         boundary-class="b-b-0"
                         v-model="entryToEdit.area_id"/>
              <AppInput name="hotel_name" :label="$t('hotel.hotel_name')" type="string-length:30" rules="required"
                        v-model="entryToEdit.name"/>
              <AppInput name="address" :label="$t('common.address')" type="string" rules="required"
                        v-model="entryToEdit.address"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToEdit.phone"/>
              <AppInput name="email" :label="$t('common.mail address')" type="email"
                        :rules="V_EMAIL"
                        v-model="entryToEdit.email"/>
              <AppInput type="number-length:10" name="fax" :label="$t('common.fax')" rules="fax"
                        v-model="entryToEdit.fax"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('hotel.hotel list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.HOTEL_LIST,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.HOTEL_DELETE,
        }
      },
      areaList: [],
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        deleteEntry: true,
        others: [
          {
            label: this.$t('hotel.room type'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.HOTEL_ROOM_TYPE_LIST,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default">${this.$t('hotel.room type')}</div>`,
          },
          {
            label: this.$t('hotel.fee rule'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.HOTEL_FEE_RULE_LIST,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default">${this.$t('hotel.fee rule')}</div>`,
          },
        ]
      },
      tools: {
        search: {
          // reset: true,
          // searchAll: true,
          filters: []
        }
      },
      entrySelectedDelete: {},
      entryToSave: {},
      entryToEdit: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'area.name', label: this.$t('common.area name'), class: 'min-w-80', sortable: false},
        {name: 'name', label: this.$t('hotel.hotel_name'), class: 'min-w-80', detailHeader: true},
        {name: 'address', label: this.$t('common.address'), sortable: false},
        {name: 'phone', label: this.$t('common.phone'), sortable: false},
        {name: 'email', label: this.$t('common.mail address'), class: 'min-w-80', sortable: false},
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.AREA_LIST_SELECT, null, {
      noLoadingEffect: true,
    }).then(res => {
      if (!res.hasErrors()) {
        this.areaList = res.data.data
      }
    })
  },
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {}
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {name, email, phone, address, fax, area_id} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.HOTEL_SAVE, {
        name, email, phone, address, fax, area_id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {name, email, phone, address, fax, area_id} = this.entryToEdit
      const res = await this.$request.patch(this.$consts.API.ADMIN.HOTEL_EDIT(this.entryToEdit.id), {
        name, email, phone, address, fax, area_id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    /* UPDATE entry end */
  }
}
</script>
